import { graphql, useStaticQuery } from "gatsby";

type QueryResult = {
  site: {
    siteMetadata: {
      siteDescription: string;
      siteName: string;
      siteUrl: string;
    };
  };
};

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<QueryResult>(
    graphql`
      query {
        site {
          siteMetadata {
            siteDescription
            siteName
            siteUrl
          }
        }
      }
    `
  );
  return site.siteMetadata;
};
