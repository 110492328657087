import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link, useLocation } from "@reach/router";
import React from "react";

import SignUpButton from "./SignUpButton";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "flex-end",
  },
  tabsRoot: {
    height: "100%",
  },
  tabsFlexContainer: {
    height: "100%",
  },
  appBarLaunchButtons: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bottomAppBar: {
    top: "auto",
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const links = [
  {
    name: "About",
    path: "/",
  },
  {
    name: "Pricing",
    path: "/pricing",
  },
];

const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const trigger = useScrollTrigger();

  const launchButtons = (
    <Grid container spacing={2} justify="flex-end">
      <Grid item>
        <Button
          color="secondary"
          href="https://app.sumitfinance.com/"
          size="large"
          variant="outlined"
          startIcon={<ExitToAppIcon />}
        >
          Open app
        </Button>
      </Grid>
      <Grid item>
        <SignUpButton />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box position="sticky" top={0} zIndex={theme.zIndex.appBar}>
        <Slide direction="down" in={!trigger || largeScreen}>
          <AppBar color="default" position="relative">
            <Container>
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12} md="auto">
                  <Tabs
                    aria-label="simple tabs example"
                    value={links.findIndex((l) => location.pathname === l.path)}
                    classes={{
                      root: classes.tabsRoot,
                      flexContainer: classes.tabsFlexContainer,
                    }}
                  >
                    {links.map((l) => (
                      <Tab
                        key={l.path}
                        component={Link}
                        label={l.name}
                        to={l.path}
                      />
                    ))}
                  </Tabs>
                </Grid>
                <Grid
                  item
                  className={classes.appBarLaunchButtons}
                  xs={12}
                  md="auto"
                >
                  <Toolbar className={classes.toolbar}>{launchButtons}</Toolbar>
                </Grid>
              </Grid>
            </Container>
          </AppBar>
        </Slide>
      </Box>

      <Slide direction="up" in={!trigger || largeScreen}>
        <AppBar
          color="default"
          position="fixed"
          className={classes.bottomAppBar}
        >
          <Container>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item xs={12} md="auto">
                <Toolbar className={classes.toolbar}>{launchButtons}</Toolbar>
              </Grid>
            </Grid>
          </Container>
        </AppBar>
      </Slide>
    </>
  );
};

export default Header;
