import { Box, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { useSiteMetadata } from "../hooks/useSiteMetadata";

const Footer = () => {
  const { siteName } = useSiteMetadata();

  return (
    <Box mt={8}>
      <Container component="footer">
        <Grid alignItems="center" container justify="space-around">
          <Grid item>
            <Typography variant="overline">Made in Brisbane! 🌄</Typography>
          </Grid>
          <Grid item>
            <Typography variant="overline">
              {`© ${new Date().getFullYear()} ${siteName}, All rights reserved.`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
