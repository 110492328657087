import { Button, ButtonProps } from "@material-ui/core";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import React from "react";

export default function SignUpButton(props: ButtonProps) {
  // Weird TS error when I destructure full props onto button. I'll just pick the ones I want for now...
  const { fullWidth } = props;
  return (
    <Button
      color="secondary"
      href="https://app.sumitfinance.com/auth/login?skipTo=signUp"
      size="large"
      target="_blank"
      variant="contained"
      startIcon={<PlaylistAddCheckIcon />}
      rel="noopener"
      fullWidth={fullWidth}
      id="sign-up-button"
    >
      Sign up
    </Button>
  );
}
