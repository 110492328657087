import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

import Footer from "./Footer";
import Header from "./Header";

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <Helmet>
        <html lang="en" />
      </Helmet>
      <CssBaseline />
      <Header />
      <Container>
        <>{children}</>
      </Container>
      <Footer />
    </>
  );
}
