import { useLocation } from "@reach/router";
import keywordExtractor from "keyword-extractor";
import React from "react";
import { Helmet } from "react-helmet";

import { useSiteMetadata } from "../hooks/useSiteMetadata";

export default function Seo({
  desc,
  title,
}: {
  desc?: string;
  title?: string;
}) {
  const location = useLocation();
  const { siteDescription, siteName, siteUrl } = useSiteMetadata();

  title = title ? `${siteName} | ${title}` : siteName;
  desc = desc ? desc : siteDescription;
  const pageUrl = siteUrl + location.pathname;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        name="viewport"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        rel="stylesheet"
      />
      <title>{title}</title>
      <link rel="canonical" href={pageUrl} />
      <meta content={desc} name="description" />
      <meta content={desc} property="og:description" />
      <meta content={title} property="og:title" />
      <meta content="website" property="og:type" />
      <meta content={pageUrl} property="og:url" />
      <meta content={siteName} property="og:site_name" />
      <meta content="summary" name="twitter:card" />
      <meta content={title} name="twitter:title" />
      <meta content={desc} name="twitter:description" />
      <meta content="index,follow" name="robots" />
      <meta
        content={keywordExtractor
          .extract(desc, { remove_duplicates: true })
          .join(",")}
        name="keywords"
      />
    </Helmet>
  );
}
