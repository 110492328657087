import React from "react";
import styled, { keyframes } from "styled-components";

function plotOnBell(x: number, scale?: boolean): number {
  //This is the real workhorse of this algorithm. It returns values along a bell curve from 0 - 1 - 0 with an input of 0 - 1.
  scale = scale || false;
  const stdD = 0.125;
  const mean = 0.5;
  if (scale) {
    return (
      1 /
      ((1 / (stdD * Math.sqrt(2 * Math.PI))) *
        Math.pow(
          Math.E,
          (-1 * Math.pow(x - mean, 2)) / (2 * Math.pow(stdD, 2))
        ))
    );
  } else {
    return (
      (1 / (stdD * Math.sqrt(2 * Math.PI))) *
      Math.pow(Math.E, (-1 * Math.pow(x - mean, 2)) / (2 * Math.pow(stdD, 2))) *
      plotOnBell(0.5, true)
    );
  }
}

const createPathString = (points: number[][]) =>
  "M 0,100 " + points.join(" ") + " 100,100 z";

export default function LogoAnimated() {
  const step = 0.5;
  const limit = 100;
  const leftBell = [];
  const rightBell = [];

  for (let i = step; i < limit; i += step) {
    const plot = plotOnBell(i / limit);

    leftBell.push([i - 8, 100 - 80 * plot]);
    rightBell.push([i + 8, 100 - 90 * plot]);
  }

  const blobby = keyframes`
    0% {
        transform: scaley(1);
    }
    60% {
        transform: scaley(0.9);
    }
    100% {
        transform: scaley(1);
    }
    `;

  const AnimatedPath = styled.path`
    animation: ${blobby} 5s ease alternate infinite;
  `;

  const leftBellStyles: React.CSSProperties = {
    transformOrigin: "bottom",
  };

  const rightBellStyles: React.CSSProperties = {
    transformOrigin: "bottom",
    animationDelay: "1s",
  };

  return (
    <svg viewBox="0 0 100 100">
      <defs>
        <AnimatedPath
          id="left-bell"
          d={createPathString(leftBell)}
          style={leftBellStyles}
        />
        <AnimatedPath
          id="right-bell"
          d={createPathString(rightBell)}
          style={rightBellStyles}
        />
        <clipPath id="mask-left">
          <use href="#left-bell" />
        </clipPath>
      </defs>
      <g id="wave">
        <use href="#left-bell" fill="#b57bff" />
        <use href="#right-bell" fill="#5393fe" />
        <use href="#right-bell" fill="#f46b3f" clipPath="url(#mask-left)" />
      </g>
    </svg>
  );
}
